<template>
  <v-card tile class="vh-100">
    <v-system-bar color="darken-2" class="pr-1 height-50">
      <h3>
        <v-btn icon :to="{ name: 'timeclock' }" class="transparent">
          <v-icon color="grey lighten-1" class="">mdi-chevron-left</v-icon>
        </v-btn>
        <span v-if="timeClock && timeClock.clockStart && timeClockId">
          <span v-if="timeClockEndDate && timeClockStartDate != timeClockEndDate">
            {{ formatDateMonthDay(timeClock.clockStart) }} -
            {{ formatDateFull(timeClockEndDate) }}
          </span>
          <span v-else>
            {{ formatDateFull(timeClock.clockStart) }}
          </span>
        </span>
      </h3>
      <v-spacer></v-spacer>
      <v-switch
        small
        v-if="!timeClockId"
        v-model="useLastClock"
        @change="fillLastClock"
        :label="$t('v_timeclockdetail.t_quick_fill')"
        class="mr-4"
      >
      </v-switch>
    </v-system-bar>

    <v-alert
      v-if="!$online"
      dense
      tile
      color="gray"
      icon="mdi-alert-circle-outline"
      class="alert-offline text-headline"
    >
      {{ $t("t_no_connection") }}
    </v-alert>

    <v-card-text class="pt-0 pb-10 mt-6">
      <v-form ref="formTimeClock" v-model="isValid">
        <!-- Overview TimeClock -->
        <div class="ma-4" v-if="timeClockId">
          <v-row class="featured rounded" v-if="!showTimeEdit && timeClock && timeClock.clockStart">
            <v-col sm="8">
              <h3>
                {{ formatDate(timeClock.clockStart) }}
                <span v-if="formatDate(timeClock.clockStart) !== formatDate(timeClock.clockEnd) && timeClock.clockEnd">
                  - {{ formatDate(timeClock.clockEnd) }}</span
                >
              </h3>
              <h4>
                {{ formatTime(timeClock.clockStart) }} -
                {{ timeClock.clockEnd ? formatTime(timeClock.clockEnd) : $t("t_clocked_in") }}
              </h4>
            </v-col>
            <v-col :sm="!timeClock.clockEnd ? '4' : '3'" class="text-right mt-3">
              <h2 v-if="!timeClock.clockEnd">
                {{ timeClockDuration }}
              </h2>
              <h2 v-else>
                {{ formatDuration(timeClock.clockStart, timeClock.clockEnd) }}
              </h2>
            </v-col>
            <v-col sm="1" class="text-right mt-1" v-if="timeClock.clockEnd">
              <v-btn icon @click="showTimeEdit = !showTimeEdit">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- /Overview TimeClock -->

        <!-- DateTime edit -->
        <div v-if="showTimeEdit" class="featured rounded px-2">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="$t('v_timeclockdetail.t_duration')"
                placeholder="00:00"
                autocomplete="off"
                v-model="timeClockNewDuration"
                :rules="[(v) => !!v || $t('t_val_req_time')]"
                required
                color="primary"
                style="font-size: 24px"
                class="centered-input"
                onclick="this.select()"
                @change="
                  changeTimeClockDuration($event);
                  timeInputsTouched.duration = true;
                "
              >
                <v-icon slot="prepend"> mdi-timer-sand </v-icon></v-text-field
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" sm="4">
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      $vuetify.breakpoint.mobile ? formatDateShort(timeClockStartDate) : formatDate(timeClockStartDate)
                    "
                    :label="$t('v_timeclockdetail.t_start_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="timeClockStartDate"
                  @input="
                    menuStartDate = false;
                    changeTime();
                  "
                  :locale="locale"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1" sm="1" class="text-center pt-3 px-0 max-width-30">
              <v-icon
                @click="resetEndDate"
                class="mt-3"
                v-if="(timeClockEndDate && timeClockStartDate != timeClockEndDate) || showEndDate"
                >mdi-minus</v-icon
              >
              <v-icon @click="showEndDate = !showEndDate" class="mt-3" v-else>mdi-plus</v-icon>
            </v-col>
            <v-col cols="12" sm="5" v-if="(timeClockEndDate && timeClockStartDate != timeClockEndDate) || showEndDate">
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDateFull(timeClockEndDate)"
                    :label="$t('v_timeclockdetail.t_end_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="timeClockEndDate"
                  @input="
                    menuEndDate = false;
                    changeTime();
                  "
                  :locale="locale"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="4">
              <v-menu
                ref="menuStartTime"
                v-model="menuStartTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="timeClockStartTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatTime(timeClockStartTime, true)"
                    :label="$t('v_timeclockdetail.t_start_time')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuStartTime"
                  v-model="timeClockStartTime"
                  full-width
                  :format="settings.timeFormat24Hour ? '24hr' : 'ampm'"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartTime = false"> {{ $t("t_cancel") }} </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      timeInputsTouched.startTime = true;
                      $refs.menuStartTime.save(timeClockStartTime);
                      changeTime();
                    "
                  >
                    {{ $t("t_ok") }}
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="1" sm="1" class="text-center pt-3 px-0 max-width-30"></v-col>
            <v-col cols="5" sm="4">
              <v-menu
                ref="menuEndTime"
                v-model="menuEndTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="timeClockEndTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatTime(timeClockEndTime, true)"
                    :label="$t('v_timeclockdetail.t_end_time')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuEndTime"
                  v-model="timeClockEndTime"
                  full-width
                  :format="settings.timeFormat24Hour ? '24hr' : 'ampm'"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="timeClockEndTime = false"> {{ $t("t_cancel") }} </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      timeInputsTouched.endTime = true;
                      $refs.menuEndTime.save(timeClockEndTime);
                      changeTime();
                    "
                  >
                    {{ $t("t_ok") }}
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <!-- /DateTime edit -->
        <div v-if="timeClock">
          <v-row>
            <v-col class="pt-6 mt-1 mb-3">
              <div v-if="timeClock.status == 'a'">
                <v-icon color="success">mdi-eye-check</v-icon>
                <span class="px-2 heading">
                  {{ $t("v_timeclockdetail.t_approved") }}
                  {{ formatDateTime(timeClock.reviewedOn) }}
                </span>
              </div>
              <div v-else-if="timeClock.status == 'i'">
                <v-icon color="warning">mdi-eye-remove</v-icon>
                <span class="px-2 heading">
                  {{ $t("v_timeclockdetail.t_denied") }}
                  {{ formatDateTime(timeClock.reviewedOn) }} {{ $t("v_timeclockdetail.t_by") }}
                  {{ timeClock.reviewedByFriendlyName }}
                </span>
              </div>
              <div v-else>
                <v-icon color="grey lighten-1">mdi-eye-off</v-icon
                ><span class="px-2 heading"> {{ $t("v_timeclockdetail.t_pending_approval") }} </span>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-select
          v-if="(timeClock && timeClock.id != null && timeClock.jobId) || (timeClock && !timeClock.id && jobs?.length)"
          :disabled="timeClock.id != null"
          v-model="timeClock.jobId"
          :items="jobs"
          item-text="jobName"
          item-value="jobId"
          :rules="[(v) => v != null || $t('t_val_req_job')]"
          required
          @input="getCostCodes"
        >
          <v-icon slot="prepend"> mdi-map-marker </v-icon>
          <template #label>
            {{ $t("v_timeclockdetail.t_job_worksite") }}
            <span class="required"></span>
          </template>
        </v-select>
        <v-select
          v-if="timeClock && timeCategories?.length"
          v-model="timeClock.timeCategoryId"
          :items="timeCategories"
          item-text="name"
          item-value="id"
          :rules="[(v) => v != null || $t('c_clockin.t_val_req_category')]"
          required
        >
          <v-icon slot="prepend"> mdi-account-hard-hat </v-icon>
          <template #label>
            {{ $t("v_timeclockdetail.t_category") }}
          </template>
        </v-select>
        <v-select
          v-if="timeClock && costCodes?.length"
          v-model="timeClock.costCodeId"
          :items="costCodes"
          item-value="costCodeId"
          :rules="[(v) => v != null || $t('c_clockin.t_val_req_costcode')]"
          required
          :no-data-text="$t('c_clockin.t_timeclock_no_codes_for_this_job')"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.costCodeCode }} : {{ data.item.costCodeDescription }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.costCodeCode }} : {{ data.item.costCodeDescription }}
          </template>
          <v-icon slot="prepend"> mdi-currency-usd </v-icon>
          <template #label>
            {{ $t("v_timeclockdetail.t_cost_code") }}
          </template>
        </v-select>
        <v-list two-line class="pt-0" v-if="fitForDutyCheck">
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                v-model="clockInNote.fitForDuty"
                :rules="[(v) => !!v || $t('t_val_req_fit_for_duty')]"
                required
                :disabled="fitForDutyCheck"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("v_timeclockdetail.t_fit_for_duty") }}</v-list-item-title>
              <v-list-item-subtitle v-if="timeClock.clockStart && timeClock.clockEnd">
                {{ formatDateTime(timeClock.clockStart) }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list class="mt-3" two-line v-if="timeClock && timeClock.notes">
          <div v-for="(note, index) in timeClock.notes" :key="index + '-check'">
            <v-divider :key="note.id" v-if="index > 0"></v-divider>
            <div>
              <div class="ma-0 px-3 text-right" v-if="!timeClock.id">
                <v-checkbox
                  class="d-inline-block ma-0 pa-0"
                  v-model="timeClock.notes[index].incidentReport"
                  :label="$t('v_timeclockdetail.t_witnessed_a_safety_incident')"
                  color="warning"
                  hide-details
                ></v-checkbox>
              </div>
              <v-list-item :key="index" class="ml-0 pl-1 pr-0" v-if="!note.id || note.note">
                <v-list-item-avatar>
                  <AppUserAvatar :user="user"></AppUserAvatar>
                </v-list-item-avatar>
                <v-list-item-content class="pb-1">
                  <v-list-item-subtitle v-if="note.id"
                    >{{ formatDateTime(note.createdOn) }}
                    <span v-if="note.parentTimeClockNoteId"
                      ><small>{{ $t("v_timeclockdetail.t_[edited]") }}</small></span
                    >
                    <span v-if="note.incidentReport" class="float-right orange--text text--darken-2"
                      ><small>{{ $t("v_timeclockdetail.t_incident_reported") }}</small></span
                    >
                  </v-list-item-subtitle>
                  <v-list-item-title v-if="note.createdById == user.id">
                    <v-textarea
                      name="owned-note"
                      filled
                      auto-grow
                      :label="
                        note.fitForDuty
                          ? 'Clock-in note'
                          : note.incidentReport
                          ? $t('v_timeclockdetail.t_incident_details')
                          : note.note
                          ? $t('v_timeclockdetail.t_note')
                          : $t('v_timeclockdetail.t_add_note')
                      "
                      value=""
                      rows="1"
                      row-height="15"
                      :dense="true"
                      v-model="timeClock.notes[index].note"
                      :rules="
                        timeClock.notes[index].incidentReport === true
                          ? [(v) => !!v || $t('t_val_req_provide_incident_details')]
                          : []
                      "
                    >
                    </v-textarea>
                  </v-list-item-title>

                  <v-list-item-title v-if="note.createdById != user.id">{{ note.note }} </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                  class="mx-0 ml-2"
                  v-if="note.createdById == user.id && note.id && timeClock.status == 'p'"
                >
                  <v-btn icon @click="timeClock.notes[index].note = ''"
                    ><v-icon color="grey lighten-1">mdi-delete</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </div>
          </div>
        </v-list>
        <div v-if="!$online && timeClock && timeClock.clockStartNote">
          <span class="caption">{{ $t("v_timeclockdetail.t_clockin_note") }}</span
          ><br />{{ timeClock.clockStartNote }}
        </div>
        <div v-if="!$online && timeClock && timeClock.clockEndNote">
          <span class="caption">{{ $t("v_timeclockdetail.t_t_clockout_note") }}</span
          ><br />{{ timeClock.clockEndNote }}
        </div>
        <div v-if="timeClock" :class="timeClock.notes && timeClock.notes.length ? 'mt-0' : 'mt-5'"></div>
        <v-card-actions v-if="timeClock">
          <v-btn :to="{ name: 'timeclock' }" small> {{ $t("t_cancel") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$online"
            :disabled="timeClock.status == 'a' || timeClock.status == 'i' || !isValid"
            color="primary"
            class="mr-4 px-9"
            @click="validateAndSubmit"
          >
            {{ timeClock.id ? $t("v_timeclockdetail.t_update") : $t("v_timeclockdetail.t_submit") }}
          </v-btn>
          <span v-else-if="!timeClock.reviewedOn">{{
            $t("v_timeclockdetail.t_updates_require_a_network_connection")
          }}</span>
          <span class="success--text" v-if="timeClock.status == 'a'">{{
            $t("v_timeclockdetail.t_time_entry_approved")
          }}</span>
          <span class="warning--text" v-if="timeClock.status == 'i'">{{
            $t("v_timeclockdetail.t_time_entry_denied")
          }}</span>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import { DateTime, Duration } from "luxon";
import AppUserAvatar from "@/components/AppUserAvatar.vue";

export default {
  name: "TimeClockDetail",
  metaInfo() {
    return {
      title: this.timeClockStartDate ? `${this.timeClockStartDate}` : "Detail",
    };
  },
  components: {
    AppUserAvatar,
  },
  model: {},
  mixins: [Helpers],
  computed: {
    ...mapGetters("timeClock", ["timeClockDuration"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("settings", ["settings"]),
    fitForDutyCheck() {
      if (this.timeClock?.notes) {
        return this.timeClock.notes.some((val) => val.fitForDuty);
      }
      return false;
    },
    clockInNote() {
      if (this.fitForDutyCheck) {
        return this.timeClock.notes.find((val) => val.fitForDuty);
      }
      return false;
    },
    locale() {
      return this.$store.state?.settings?.lang;
    },
  },
  data() {
    return {
      timeClock: null,
      timeclockNotesOrig: null,
      showTimeEdit: false,
      showEndDate: false,
      menuStartDate: false,
      menuEndDate: false,
      menuStartTime: false,
      menuEndTime: false,
      timeClockStartDate: DateTime.now().toISODate(),
      timeClockEndDate: DateTime.now().toISODate(),
      timeClockStartTime: "09:00:00",
      timeClockEndTime: "09:00:00",
      timeClockNewDuration: "00:00",
      timeClockId: null,
      jobs: [],
      timeCategories: [],
      costCodes: [],
      isValid: false,
      useLastClock: false,
      timeInputsTouched: { duration: false, startTime: false, endTime: false },
    };
  },
  methods: {
    ...mapActions("timeClock", [
      "getTimeClock",
      "getTimeClockLatest",
      "saveTimeClock",
      "saveTimeClockNote",
      "getUserJobs",
      "getCategories",
      "getJobCostCodes",
      "resetTimeClockState",
    ]),
    resetEndDate() {
      if (this.timeClockStartTime < this.timeClockEndTime) {
        this.showEndDate = false;
        this.timeClockEndDate = this.timeClockStartDate;
        this.changeTime();
      }
    },
    parseStartTime(e) {
      let timeString = e.target.value;
      let d = this.parseTime(timeString);
      this.timeClockStartTime = DateTime.fromJSDate(d).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      this.changeTime();
    },
    parseEndTime(e) {
      let timeString = e.target.value;
      let d = this.parseTime(timeString);
      this.timeClockEndTime = DateTime.fromJSDate(d).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      this.changeTime();
    },
    pastDates(val) {
      return val <= new Date().toISOString().substr(0, 10);
    },
    changeTime() {
      if (this.timeInputsTouched.startTime && !this.timeInputsTouched.endTime && !this.timeInputsTouched.duration) {
        return;
      }

      if (!this.timeClockEndDate) {
        this.timeClockEndDate = this.timeClockStartDate;
      }

      let startDate = DateTime.fromISO(this.timeClockStartDate + "T" + this.timeClockStartTime);
      let endDate = DateTime.fromISO(this.timeClockEndDate + "T" + this.timeClockEndTime);
      this.timeClockNewDuration = this.formatDuration(startDate, endDate);

      //if duration pushes to next day... set end date - don't check if just start time touched
      let diffCheck = endDate.diff(startDate).toMillis();
      if (diffCheck < 0) {
        this.timeClockEndDate = DateTime.fromISO(this.timeClockEndDate).plus({ days: 1 }).toISODate();
      }
    },

    changeTimeClockDuration(val) {
      let duration = Duration.fromISOTime(val);
      if (duration.invalid && val.indexOf(":") > 0) {
        let [hour, minute] = val.split(":");
        if (hour.length == 1) {
          hour = 0 + hour;
        }
        val = hour + ":" + minute;
        duration = Duration.fromISOTime(val);
      } else if (duration.invalid && val.indexOf(".") > -1) {
        val = this.convertNumToTime(val);
        duration = Duration.fromISOTime(val);
      } else if (duration.invalid) {
        if (val.length == 1) {
          val = "0" + val + ":00";
        }
        if (val.length == 3 && val.charAt(0) == 0) {
          val = "00:" + val.charAt(1) + val.charAt(2);
        }
        if (val.startsWith(":")) {
          val = "00" + val;
        }
        duration = Duration.fromISOTime(val);
      }

      this.timeClockNewDuration = duration.toFormat("h:mm");
      let start = DateTime.fromISO(this.timeClockStartDate + "T" + this.timeClockStartTime);
      let end = start.plus(duration);
      this.timeClockEndDate = end.toISODate();
      this.timeClockEndTime = this.formatTime24(end);
    },
    addEmptyNote() {
      let note = {
        createdById: this.user ? this.user.id : null,
        createdOn: DateTime.utc().toISO(),
        fitForDuty: false,
        note: "",
        timeClockId: this.timeClockId,
      };
      if (this.timeClock && this.timeClock.notes?.length > 0) {
        this.timeClock.notes.unshift(note);
      } else {
        this.$set(this.timeClock, "notes", [note]);
      }
    },
    validateAndSubmit() {
      this.isValid = this.$refs.formTimeClock.validate();
      if (this.isValid) {
        this.submitTimeClock();
      } else {
        this.$snackbar.showMessage({
          content: this.$t("t_something_went_wrong"),
          color: "warning",
          timeout: "",
        });
      }
    },

    fillLastClock() {
      if (this.useLastClock) {
        this.getTimeClockLatest().then((lastClock) => {
          const skipTime = Object.values(this.timeInputsTouched).includes(true);
          if (!skipTime) {
            if (lastClock.clockEnd) {
              this.timeClockStartTime = this.formatTime24(lastClock.clockStart);
              this.timeClockEndTime = this.formatTime24(lastClock.clockEnd);
            }
            this.changeTime();
          }
          this.timeClock.timeCategoryId = lastClock.timeCategoryId;
          this.timeClock.costCodeId = lastClock.costCodeId;
          this.timeClock.jobId = lastClock.jobId;
          this.getCostCodes(lastClock.jobId);
        });
      }
    },

    async submitTimeClock() {
      var clockEdit = {};
      clockEdit.id = this.timeClock.id;
      clockEdit.clockStart = this.timeClock.clockStart;
      clockEdit.clockEnd = this.timeClock.clockEnd;
      clockEdit.timeCategoryId = this.timeClock.timeCategoryId;
      clockEdit.costCodeId = this.timeClock.costCodeId;
      clockEdit.clockStartReasonId = this.timeClock.clockStartReasonId;
      clockEdit.clockStartLatLng = this.timeClock.clockStartLatLng;
      clockEdit.clockEndLatLng = this.timeClock.clockEndLatLng;
      clockEdit.clockStartLatLngMethod = this.getDeviceInfo();
      clockEdit.clockEndLatLngMethod = this.getDeviceInfo();
      //if times added or reset
      if (this.showTimeEdit) {
        let clockStart = DateTime.fromISO(this.timeClockStartDate + "T" + this.timeClockStartTime);
        if (!this.timeClockEndDate) {
          this.timeClockEndDate = this.timeClockStartDate;
        }
        let clockEnd = DateTime.fromISO(this.timeClockEndDate + "T" + this.timeClockEndTime);
        clockEdit.clockStart = clockStart.setZone("utc").toISO();
        clockEdit.clockEnd = clockEnd.setZone("utc").toISO();
      }

      let saveClock = [];

      if (!this.timeClock.id) {
        clockEdit.jobId = this.timeClock.jobId;
      } else {
        this.timeClock.notes.forEach((note) => {
          if (note.note) {
            let checkNote = this.timeclockNotesOrig.find((val) => val.id == note.id);
            if (checkNote?.note !== note.note) {
              this.saveNote(note, saveClock);
            }
          } else if (note.id) {
            this.saveNote(note, saveClock); //delete note
          }
        });
      }

      saveClock.push(this.saveTimeClock(clockEdit));
      let results = await Promise.all(saveClock);

      if (!this.timeClock.id) {
        let timeClockId = results[0]?.data.timeclockId;
        if (timeClockId && this.timeClock.notes[0] && this.timeClock.notes[0].note) {
          saveClock = [];
          let note = this.timeClock.notes[0]; //only one exists for add
          note.timeClockId = timeClockId;
          this.saveNote(note, saveClock);
          await Promise.all(saveClock);
        }
      }

      this.responseMessage("c_snackbar.t_success_timeclock_saved", 2000);
      this.$router.push({
        name: "timeclock",
        params: { fetchTimeClocks: true },
      });
    },
    saveNote(note, saveClock) {
      let noteEdit = {};
      if (note.id) {
        noteEdit.id = note.id;
      }
      noteEdit.timeClockId = note.timeClockId ? note.timeClockId : this.timeClock.id;
      noteEdit.note = note.note;
      noteEdit.incidentReport = note.incidentReport;
      noteEdit.fitForDuty = note.fitForDuty;
      return saveClock.push(this.saveTimeClockNote(noteEdit));
    },
    formTimeClockReset() {
      this.$refs.formTimeClock.reset();
    },
    resetClockIn() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    getCostCodes() {
      this.costCodes = [];
      let params = {};
      params.jobId = this.timeClock.jobId;
      this.getJobCostCodes(params).then((response) => {
        this.costCodes = response;
      });
    },
  },

  mounted() {
    let params = this.$route.params;
    this.timeClockId = params.timeClockId;
    if (this.$online) {
      this.isValid = false;
    }

    if (this.timeClockId) {
      this.timeClock = null;
      this.getTimeClock(this.timeClockId).then((r) => {
        this.timeClock = r;
        this.getCostCodes();
        if (this.timeClock && this.timeClock.clockEnd) {
          this.timeClockStartDate = DateTime.fromISO(this.timeClock.clockStart).toISODate();
          this.timeClockEndDate = DateTime.fromISO(this.timeClock.clockEnd).toISODate();
          this.timeClockStartTime = this.formatTime24(this.timeClock.clockStart);
          this.timeClockEndTime = this.formatTime24(this.timeClock.clockEnd);
          this.showTimeEdit = true;
        } else {
          this.showTimeEdit = false;
        }
        this.timeClockNewDuration = this.formatDuration(this.timeClock.clockStart, this.timeClock.clockEnd);
        this.timeClock.notes?.sort((a, b) => b.id - a.id); //creation order descending
        //this.timeclockNotesOrig = [...this.timeClock.notes];//reactivity persists, thus...
        this.timeclockNotesOrig = JSON.parse(JSON.stringify(this.timeClock.notes));
        this.addEmptyNote();
      });
    } else {
      this.resetTimeClockState();
      this.timeClock = this.$store.state.timeClock.timeClock;
      this.$refs.formTimeClock.resetValidation();
      this.showTimeEdit = true;
      this.timeClock.userId = this.user.id;
      this.timeClock.companyId = this.user.companyId;
      this.timeClock.createdById = this.user.id;
      this.timeClock.clockStart = DateTime.utc().toISO();
      this.timeClock.clockEnd = DateTime.utc().toISO();
      this.timeClockEndDate = null;
      this.timeClockNewDuration = this.formatDuration(this.timeClock.clockStart, this.timeClock.clockEnd);
      this.createdOn = DateTime.utc();
      this.addEmptyNote();
    }
    const jobParams = { userId: this.$store.state.user.user.userId };
    this.getUserJobs(jobParams).then((response) => {
      this.jobs = response;
    });
    this.getCategories().then((response) => {
      this.timeCategories = response;
    });
  },
};
</script>
<style lang="scss">
.clockin-dialog {
  //height: calc(100% - 190px) !important;
  bottom: 0;
  top: initial !important;
  border-top: 5px solid #777;
  background: #aaa;
}
.max-width-30 {
  max-width: 30px;
}
.height-50 {
  height: 50px !important;
}
</style>
